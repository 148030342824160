import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'yhelper-background',
    templateUrl: 'background.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['background.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackgroundComponent {

    @Input() mode: 'abstracts' = 'abstracts';
    @Input() colorClass: string = 'bg-gray-800';

    constructor() {
    }

}
