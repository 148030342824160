import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import {catchError, Observable, of, tap} from 'rxjs';
import { AuthService } from 'app/services/auth.service';
import {SharedWorkerService} from "../../services/shared-worker.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(
        private authService: AuthService,
        private router: Router,
        private sharedWorkerService: SharedWorkerService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this._check(state.url);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this._check(state.url);
    }

    private _check(redirectURL: string): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            if (this.authService.isAuthenticated()) {
                observer.next(true);
            } else if (this.authService.tokenExist()) {
                this.authService.getOperator().subscribe(() => {
                    observer.next(this.authService.isAuthenticated());
                }, observer.error.bind(observer));
            } else {
                observer.next(false);
            }
        }).pipe(
            catchError(() => {
                return of(false);
            }),
            tap((authenticated) => {
                if (!authenticated) {
                    // Redirect to the sign-in page
                    this.router.navigate([''], {queryParams: {redirectURL}});
                    this.sharedWorkerService.stopWorker(true);
                }
            })
        );
    }

}
