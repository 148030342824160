import { Injectable } from "@angular/core";
import { AuthRestService, SignIn } from "./rest/auth.rest.service";
import { BehaviorSubject, map, Observable, tap } from "rxjs";
import { StorageService } from "./storage.service";
import { LocalStorageConfig } from "../core/config/localStorage.config";
import OperatorEntity from "../entity/operator.entity";
import { SecurityRestService } from "./rest/security.rest.service";
import { Router } from "@angular/router";
import { RoutesHelper } from '../core/helpers/routes.helper';

@Injectable()
export class AuthService {

    private _operator: OperatorEntity;
    private operatorLoaded = new BehaviorSubject<OperatorEntity>(null);
    $operator = this.operatorLoaded.asObservable();

    get operator(): OperatorEntity {
        return this._operator;
    }

    set operator(value: any) {
        this._operator = new OperatorEntity(value || {});
        this.operatorLoaded.next(value ? this._operator : null);
    }

    get token(): string {
        return this.storageService.get(LocalStorageConfig.keys.accessToken);
    }

    get chatToken(): string {
        return this.operator.token;
    }

    constructor(private authRestService: AuthRestService,
                private securityRestService: SecurityRestService,
                private storageService: StorageService,
                private router: Router) {
    }

    tokenExist() {
        return !!this.token;
    }

    isAuthenticated() {
        return this.operator && this.operator.id && this.tokenExist();
    }

    setToken(value: string | null, remember?: boolean) {
        if (value) {
            const saveType = remember ? 'local' : 'session';
            this.storageService.set(LocalStorageConfig.keys.accessToken, value, saveType);
        } else {
            this.storageService.remove(LocalStorageConfig.keys.accessToken);
        }
    }

    signIn(form: SignIn, rememberMe?: boolean): Observable<OperatorEntity> {
        return this.authRestService.signIn(form).pipe(
            tap(({'access_token': accessToken, operator}: any) => {
                this.setToken(accessToken, rememberMe);
                this.operator = operator;
            }),
            map(() => this.operator)
        );
    }

    signOut(redirect: boolean = false) {
        this.setToken(null);
        this.operator = null;
        if (redirect) {
            const {'url': redirectURL} = this.router;
            this.router.navigate([ RoutesHelper.Auth ], { queryParams: { redirectURL } });
        }
    }

    getOperator() {
        return this.securityRestService.getMe().pipe(
            tap((operator) => {
                this.operator = operator;
            })
        );
    }

}
