import {Directive, ElementRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {debounceTime, Subject, takeUntil} from 'rxjs';

interface IResizeObserverEvent {
    entries: ResizeObserverEntry[];
    observer: ResizeObserver;
}

@Directive({
    selector: '[resizeObserver]'
})
export class ResizeObserverDirective implements OnInit, OnDestroy {
    
    @Input() emitWindowResizeEvent = true;
    
    @Output() resizeObserver = new Subject<IResizeObserverEvent>();
    
    private observer: ResizeObserver;
    
    constructor(private elementRef: ElementRef) { }
    
    ngOnInit() {
        this.observer = new ResizeObserver(this.onResize.bind(this));
        this.observer.observe(this.elementRef.nativeElement);
    }
    
    ngOnDestroy() {
        this.observer.disconnect();
    }

    private onResize(entries: ResizeObserverEntry[], observer: ResizeObserver): void {
        this.resizeObserver.next({ entries, observer });
        if (this.emitWindowResizeEvent) {
            dispatchEvent(new Event('resize'));
        }
    }
}
