export enum SecurityEditGroupNavigationEnum {
    edit = 'edit',
}

export enum SecurityEditGroupTabsNavigationEnum {
    common = 'common',
    permissions = 'permissions',
    emails = 'emails',
    projects = 'projects',
    advanced = 'advanced',
}


