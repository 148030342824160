import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseFullscreenModule } from '@fuse/components/fullscreen';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { NotificationsModule } from 'app/components/notifications/notifications.module';
import { SearchModule } from 'app/components/search/search.module';
import { ShortcutsModule } from 'app/components/shortcuts/shortcuts.module';
import { UserModule } from 'app/components/user/user.module';
import { DenseLayoutComponent } from 'app/layout/layouts/dense/dense.component';
import {LogoModule} from "../../../components/logo/logo.module";
import {ChatService} from "../../../services/chat.service";
import {ResizeObserverModule} from '../../../directives/resize-observer/resize-observer.module';

@NgModule({
    declarations: [
        DenseLayoutComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        FuseFullscreenModule,
        FuseLoadingBarModule,
        FuseNavigationModule,
        NotificationsModule,
        SearchModule,
        ShortcutsModule,
        UserModule,
        LogoModule,
        ResizeObserverModule,
    ],
    exports: [
        DenseLayoutComponent
    ],
    providers: [
        ChatService
    ]
})
export class DenseLayoutModule {

}
