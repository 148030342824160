import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { map, Observable, of, tap } from 'rxjs';
import { AuthService } from 'app/services/auth.service';
import { SharedWorkerService } from "../../services/shared-worker.service";
import { CrmModulePath } from '../../navigation/crm';

@Injectable({
    providedIn: 'root'
})
export class NoAuthGuard implements CanActivate, CanActivateChild {

    constructor(
        private authService: AuthService,
        private router: Router,
        private sharedWorkerService: SharedWorkerService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this._check();
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this._check();
    }

    private _check(): Observable<boolean> {
        return of(this.authService.tokenExist()).pipe(
            tap((authenticated) => {
                if (authenticated) {
                    this.router.navigate([CrmModulePath]);
                } else {
                    this.sharedWorkerService.stopWorker(true);
                }
            }),
            map(authenticated => !authenticated)
        );
    }
}
