import BaseEntity from "./@base.entity";

export default class EmailsEntity extends BaseEntity {

    id;
    email;
    projectId;

    constructor(data: any = {}) {
        super(data);
        this.id = data.email_id;
        this.email = data.email;
        this.projectId = data.project_id;
    }

}
