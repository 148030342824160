import {TYhRoute} from '../../../navigation-interfaces/navigation.interfaces';
import {PermissionsGuard} from '../../../../core/guards/permissions.guard';
import { YhPermissions } from '../../../../core/config/yh-permissions';

export enum SettingsNewsFeedGroupNavigationEnum {
    root = 'settings',
    groups = 'news-feed-groups',
    permissions = 'permissions',
    operators = 'operators',
}

export const SettingsNewsFeedGroupsNavigation: TYhRoute[] = [
    {
        path: 'news-feed-groups',
        loadChildren: () => import('app/modules/crm/components/settings/news-feed-groups/news-feed-groups.module').then(m => m.NewsFeedGroupsModule),
        fuseNavConfig: {
            title: 'News feed groups',
            type: 'basic',
        },
        data: {
            permission: YhPermissions.newsFeed.view
        },
        canActivate: [PermissionsGuard]
    }
];
