import BaseEntity from "./@base.entity";

export default class LocaleEntity extends BaseEntity {

    enabled?: boolean;
    id;
    name;

    constructor(data: any = {}) {
        super(data);
        this.id = data.id;
        this.name = data.name;
    }

    toggleEnabled() {
        this.enabled = !this.enabled;
    }

}
