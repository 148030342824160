import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import {HttpClientModule} from "@angular/common/http";
import {RouterModule} from "@angular/router";
import { LayoutComponent } from 'app/layout/layout.component';
import { EmptyLayoutModule } from 'app/layout/layouts/empty/empty.module';
import { DenseLayoutModule } from 'app/layout/layouts/dense/dense.module';

const layoutModules = [
    // Empty
    EmptyLayoutModule,
    // Vertical navigation
    DenseLayoutModule
];

@NgModule({
    declarations: [
        LayoutComponent
    ],
    imports     : [
        CommonModule,
        HttpClientModule,
        RouterModule,
        ...layoutModules
    ],
    exports     : [
        LayoutComponent,
        ...layoutModules
    ]
})
export class LayoutModule {
}
