import { ChatFormFieldTypes } from '../../core/config/chat.constants';

export type TChatFormField = 'text-input' | 'date-picker' | 'stars';

export interface IChatFormFieldRest {
    label: string;
    name: string;
    type: TChatFormField;
}

export interface IChatFormRestEntity {

    fields_definition: IChatFormFieldRest[];
    form_id: number;
    has_answer: boolean;
    success_text: string;
    title: string;
    
    // created_at: string;
    // created_by: string | null;
    // hidden: 0 | 1;
    // updated_at: string;
    // updated_by: string | null;
}

export class ChatFormEntity {
    
    fieldsDefinition: IChatFormFieldRest[] = [];
    id: number = null;
    hasAnswer = false;
    successText = '';
    title = '';
    
    constructor(data: IChatFormRestEntity, filterFields: boolean) {
        if (data) {
            this.id = data.form_id || null;
            this.title = data.title || '';
            this.hasAnswer = !!data.has_answer;
            this.successText = '';
            this.fieldsDefinition = filterFields ? this.filteredFields(data.fields_definition) : data.fields_definition;
        }
    }
    
    filteredFields(definitions: IChatFormFieldRest[], fields: TChatFormField[] = Object.values(ChatFormFieldTypes)) {
        return (definitions || []).filter( ({ type }) => fields.indexOf(type) > -1 );
    }
}
