import { NgModule, Optional, SkipSelf } from '@angular/core';
import { IconsModule } from 'app/core/icons/icons.module';
import { YhDateModule } from './date-module/yh-date.module';
import { AuthService } from '../services/auth.service';
import { PermissionsService } from '../services/permissions.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedWorkerService } from '../services/shared-worker.service';
import { YhNavigationService } from '../services/navigation.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { YhToastrModule } from '../components/yh-toastr/yh-toastr.module';
import { ErrorMessageInterceptor } from './interceptors/error-message.interceptor';

@NgModule({
    imports: [
        IconsModule,
        YhDateModule,
        YhToastrModule,
    ],
    providers: [
        AuthService,
        PermissionsService,
        {
            provide : HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi   : true
        },
        {
            provide : HTTP_INTERCEPTORS,
            useClass: ErrorMessageInterceptor,
            multi   : true
        },
        SharedWorkerService,
        YhNavigationService,
    ],
})
export class CoreModule {

    constructor(
        @Optional() @SkipSelf() parentModule?: CoreModule
    )
    {
        // Do not allow multiple injections
        if ( parentModule )
        {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
