import BaseEntity from "./@base.entity";
import GroupEntity from "./group.entity";
import OperatorProjectEntity from "./operatorProjects.entity";
import WhatsappPhonesEntity from "./whatsappPhones.entity";
import TelegramPhonesEntity from "./telegramPhones.entity";
import EmailsEntity from "./emails.entity";
import LocaleEntity from "./locale.entity";
import {OperatorStatusEnum} from "../core/enums/operator-status.enum";
import {TPermissionsCheck} from '../navigation/navigation-interfaces/navigation.interfaces';

export default class OperatorEntity extends BaseEntity {

    id: number;
    email: string;
    token: string;
    name: string;
    status: OperatorStatusEnum;
    gender: 'Male' | 'Female';
    phone: number | 'Unset';
    photo: string;
    maxChats: number;
    group: GroupEntity;
    projects: Array<OperatorProjectEntity>;
    telegramPhones: Array<TelegramPhonesEntity>;
    whatsappPhones: Array<WhatsappPhonesEntity>;
    emails: Array<EmailsEntity>;
    locales: Array<LocaleEntity>;

    constructor(data: any = {}) {
        super(data);
        this.id = data.id;
        this.email = data.email;
        this.name = data.name;
        this.token = data.token;
        // TODO remove
        // data.photo = '43fd4e4d4e16ba4a80db83ceffdeb1ed.png';
        this.photo = data.photo && String(data.photo).toLowerCase().indexOf('user.png') === -1 ? data.photo : 'user.png'; // TODO remove from rest default avatar if not set
        this.gender = +data?.gender == 1 ? 'Male': 'Female';
        this.phone = data?.phone || 'Unset';
        this.group = new GroupEntity(data.group);
        this.projects = this.transformToArrEntity(data?.projects, OperatorProjectEntity);
        this.telegramPhones = this.transformToArrEntity(data?.telegramPhones, TelegramPhonesEntity);
        this.whatsappPhones = this.transformToArrEntity(data?.whatsappPhones, WhatsappPhonesEntity);
        this.emails = this.transformToArrEntity(data?.emails, EmailsEntity);
        this.locales = this.transformToArrEntity(data?.locales, LocaleEntity);
        this.maxChats = isNaN(+data.max_chats) ? 0 : +data.max_chats;
        this.status = data?.status || OperatorStatusEnum.Unknown;
    }

    hasPermission(permission: string): boolean {
        return this.group.permissions.indexOf(permission) > -1;
    }
    
    hasPermissions(permissions: string[], logic: TPermissionsCheck): boolean {
        if (logic === 'OR') {
            // at least one permission required
            return !!permissions.find(perm => this.hasPermission(perm));
        }
        // all permissions required
        return !permissions.find(perm => !this.hasPermission(perm));
    }

}
