import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import OperatorEntity from "../../entity/operator.entity";
import {environment} from "../../../environments/environment";

@Component({
    selector: 'operator-profile-header',
    templateUrl: './operator-profile-header.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OperatorProfileHeaderComponent {

    public readonly USERS_PHOTOS_DOMAIN = environment.rest.images.users;
    @Input() operator: OperatorEntity;

    constructor() {
    }

}
