import { TRestNumericBooleanType } from '../../../../core/interfaces/common/rest-numeric-boolean.type';
import BaseEntity from '../../../../entity/@base.entity';

export interface ISecurityGroupRest {
    group_id: number;
    is_operator: TRestNumericBooleanType;
    name: string;
    org_group_name: string;
    protected: TRestNumericBooleanType;
}

export class SecurityGroupRestEntity extends BaseEntity {
    
    groupId: number;
    isOperator: boolean;
    name: string;
    orgGroupName: string;
    protected: boolean;
    
    constructor(restData: ISecurityGroupRest) {
        super(restData);
        this.groupId = restData.group_id;
        this.isOperator = !!+restData.is_operator;
        this.name = restData.name;
        this.orgGroupName = restData.org_group_name;
        this.protected = !!+restData.protected;
    }
}
