import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { YhToastrService } from '../../components/yh-toastr/yh-toastr.service';
import { Router } from '@angular/router';
import { RoutesHelper } from '../helpers/routes.helper';

interface TDetailedError {
    code: number;
    message: string;
    name: string;
    status: number;
    type: string;
}

class YhHttpErrorResponseWrapper {
    static extractError(httpErrorResponse: HttpErrorResponse) {
        return httpErrorResponse?.error || {} as Partial<TDetailedError>;
    }
}
const LOGIN_URL = RoutesHelper.SignIn;
const ERROR_CODES = [500, 418];

@Injectable()
export class ErrorMessageInterceptor implements HttpInterceptor {
    
    constructor(private yhToastrService: YhToastrService,
                private router: Router,
    ) { }
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request object
        const cloneRequest = req.clone();
        
        // Response
        return next.handle(cloneRequest).pipe(
            tap({
                error: (err: YhHttpErrorResponseWrapper) => {
                    if (err instanceof HttpErrorResponse) {
                        
                        if (err.status === 401) {
                            if (this.router.url.indexOf(LOGIN_URL) === 0) {
                                // no need to show error auth message on the login page
                                return;
                            }
                        }

                        if (err.status === 403) {
                            if (this.router.url.indexOf(LOGIN_URL) !== 0) {
                                this.yhToastrService.show('You are not authorized to perform this action').error();
                            }
                        }

                        if (ERROR_CODES.indexOf(err.status) !== -1) {
                            // TODO: review reqBody, reqMethod
                            err['reqBody'] = req.body;
                            err['reqMethod'] = req.method;
                            //
                            if (this.handleErrorFromArray(err.error)) {
                                // error message was shown from an err.error.message array. return
                                return;
                            }
                            if (err?.error?.messages?.length) {
                                this.yhToastrService.showMany(err.error.messages).error();
                            } else if (err) {
                                this.yhToastrService.showErrorMessage(err);
                            }
                            return;
                        }
                        // default:
                        const { message, name, status } =  (err.error || {}) as TDetailedError;
                        const errorText = (status || 0) + ' ' + (name ? name + '. ' : '') + (message || 'Error');
                        this.yhToastrService.show(errorText).error({ duration: 4000 });
                    }
                }
            })
        );
    }
    
    private handleErrorFromArray(error: { message: string }): boolean {
        try {
            const messages = JSON.parse(error.message);
            if (Array.isArray(messages)) {
                this.yhToastrService.showMany(messages).error();
                return true;
            }
        } catch (e) {
            // not happened...
        }
        return false;
    }
}
