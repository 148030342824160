import {TYhRoute} from "../../navigation-interfaces/navigation.interfaces";
import {SettingsEventsNavigation} from './events/settings-events.navigation';
import {SettingsAppConfigNavigation} from './app-config/settings-app-config.navigation';
import {SettingsDebugNavigation} from '../debug/settings-debug.navigation';
import {SettingsNewsFeedGroupsNavigation} from "./news-feed-groups/settings-news-feed-groups.navigation";

export const SettingsNavigation: TYhRoute[] = [
    {
        path: 'settings',
        fuseNavConfig: {
            title: 'Settings',
            type: 'collapsable',
            icon: 'settings',
        },
        permissionFree: true,
        children: [
            ...SettingsAppConfigNavigation,
            ...SettingsDebugNavigation,
            ...SettingsEventsNavigation,
            ...SettingsNewsFeedGroupsNavigation
        ]
    },
];
