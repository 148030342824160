import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { YhToastrService } from '../yh-toastr.service';

@Component({
    template: '',
})
export abstract class YhToastrAbstractComponent<D = any> {

    closeNotification: () => void;
    skipAllNotifications: () => void;

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: D,
                protected yhToastrService: YhToastrService,
    ) { }
}
