import {Injectable} from "@angular/core";
import {BaseRestService} from "./@base.rest.service";
import {Observable} from "rxjs";

export type SignIn = {email: string, password: string};

@Injectable({
    providedIn: 'root'
})
export class AuthRestService extends BaseRestService {

    readonly endpoints = {
        signIn: '/login',
        signUp: '/registration',
        passwordRecovery: '/password-recovery',
        passwordReset: `/password-reset`,
    };

    constructor() {
        super();
    }

    signIn(form: SignIn): Observable<any> {
        return this.post(this.endpoints.signIn, form); // TODO remove unused chat_token field from response
    }

}
