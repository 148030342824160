import { ChatMessageType } from '../../core/enums/chat/chat-message-type.enum';
import BaseEntity from '../@base.entity';

export interface IDeferredMessageEntity {
    chat_id: number;
    client_id: number;
    customer_client_id: number;
    delivered: 0 | 1;
    display_client: any;
    end_time: string;
    message_id: number;
    operator_id: number;
    operator_name: string;
    reply_to_message_id: number;
    text: string;
    timestamp: string;
    type_id: ChatMessageType;
}

export class DeferredMessageEntity extends BaseEntity {
    
    text: string;
    endTime: string;
    timestamp: string;
    operatorName: string;
    chatId: number;
    
    constructor(data: IDeferredMessageEntity) {
        super(data);
        const def: Partial<IDeferredMessageEntity> = data || {};
        
        this.endTime = def.end_time;
        this.text = def.text || '';
        this.timestamp = def.timestamp || '';
        this.operatorName = def.operator_name || '';
        this.chatId = def.chat_id;
        
    }
}
