import { NgModule } from '@angular/core';
import {LogoComponent} from "./logo.component";

@NgModule({
    declarations: [
        LogoComponent
    ],
    exports: [
        LogoComponent
    ]
})
export class LogoModule {
}
