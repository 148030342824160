import { YhToastrSimpleComponent } from '../templates/simple/yh-toastr-simple.component';
import { ComponentType } from '@angular/cdk/overlay';

export enum YhToastrTypeEnum {
    Simple,
}

export type TYhToastrTypeToTemplate = {
    [YhToastrTypeEnum.Simple]: YhToastrSimpleComponent;
}

type TYhToastrTypeToComponentMap = {
    [K in YhToastrTypeEnum]: () => ComponentType<TYhToastrTypeToTemplate[K]>;
}

export const YhToastrTypeToComponentMap: TYhToastrTypeToComponentMap = {
    [YhToastrTypeEnum.Simple]: () => YhToastrSimpleComponent,
} as const;
