import { LayoutComponent } from "../../layout/layout.component";
import { NoAuthGuard } from "../../core/guards/noAuth.guard";

export const AuthModulePath = 'auth';

export enum AuthNavigationEnum {
    signIn = 'sign-in',
}

export default [
    {
        path: AuthModulePath,
        canActivate: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: '',
                pathMatch : 'full',
                redirectTo: AuthNavigationEnum.signIn
            },
            {
                path: AuthNavigationEnum.signIn,
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)
            }
        ]
    }
];
