import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'yhelper-logo',
    template: `
        <div class="logo-wrapper" 
             [style]="{
                width: size + 'px',
                height: size + 'px'
             }">
            <div class="logo"
                 [style]="{
                    fontSize: fontSize + 'px'
                 }">
                Y
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['logo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {

    readonly textPercent: number = 80;
    private _size: number = 100;
    get size(): number {
        return this._size;
    }
    @Input() set size(value: number) {
        this._size = value;
        this.calcLogo();
    }
    public fontSize: number = 0;

    constructor() {
        this.calcLogo();
    }

    calcLogo() {
        this.fontSize = (this.size / 100) * this.textPercent;
    }

}
