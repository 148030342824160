import {TYhRoute} from '../../navigation-interfaces/navigation.interfaces';
import {PermissionsGuard} from '../../../core/guards/permissions.guard';

export const SettingsDebugNavigation: TYhRoute[] = [
    {
        path: 'debug',
        loadChildren: () => import('app/modules/crm/components/settings/debug/debug.module').then(m => m.DebugModule),
        fuseNavConfig: {
            title: 'Debug',
            type: 'basic',
        },
        data: {
            permission: 'developer.debugPanel',
        },
        canActivate: [PermissionsGuard]
    },
];
