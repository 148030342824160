import {ProfileComponent} from "../../../modules/crm/components/profile/profile.component";
import {TYhRoute} from "../../navigation-interfaces/navigation.interfaces";

const defaultRoutes: TYhRoute[] = [
    {
        path: 'profile',
        loadChildren: () => import('app/modules/crm/components/profile/profile.module').then(m => m.ProfileModule),
        fuseNavConfig: {
            title: 'Profile',
            type: 'basic',
            icon: 'person',
        },
        permissionFree: true,
    }
];
export default defaultRoutes;

export const ProfileModuleNavigation: TYhRoute[] = [
    {
        path: '',
        component: ProfileComponent,
        permissionFree: true,
        children: [
            {
                path: '',
                pathMatch : 'full',
                redirectTo: 'general',
                permissionFree: true,
            },
            {
                path: 'general',
                loadChildren: () => import('app/modules/crm/components/profile/general/profile-general.module').then(m => m.ProfileGeneralModule),
                permissionFree: true,
            },
            {
                path: 'personal-settings',
                loadChildren: () => import('app/modules/crm/components/profile/personal-settings/profile-personal-settings.module').then(m => m.ProfilePersonalSettingsModule),
                permissionFree: true,
            },
            {
                path: 'private-phrases',
                loadChildren: () => import('app/modules/crm/components/profile/private-phrases/profile-private-phrases.module').then(m => m.ProfilePrivatePhrasesModule),
                permissionFree: true,
            }
        ]
    }
];
