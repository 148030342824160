import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
    selector: '[clickOutside]'
})
export class ClickOutsideDirective {
    constructor(private _elementRef: ElementRef) {}
    
    @Input()
    public excludeClicks: string[] = [];
    
    @Input() excludeClicksThroughParent = true;
    
    @Output()
    public clickOutside = new EventEmitter();
    
    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement: HTMLElement) {
        const classExists = this.excludeClassExists(targetElement);
        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside && !classExists) {
            this.clickOutside.emit(null);
        }
    }
    
    private excludeClassExists(target: HTMLElement): boolean {
        if (this.excludeClicksThroughParent) {
            return this.excludeClicks.some(excludeClass => target.closest(`.${excludeClass}`));
        } else {
            return this.excludeClicks.some(excludeClass => target.classList.contains(excludeClass));
        }
    }
}
