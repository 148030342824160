import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YhToastrSimpleComponent } from './templates/simple/yh-toastr-simple.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { YhToastrService } from './yh-toastr.service';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBar } from '@angular/material/snack-bar';
import { YhMatSnackBarDefaultOptions } from './types/mat-snack-bar-default.options';



@NgModule({
    declarations: [
        YhToastrSimpleComponent,
    ],
    providers: [
        MatSnackBar,
        YhToastrService,
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: YhMatSnackBarDefaultOptions }
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
    ]
})
export class YhToastrModule { }
