import { BehaviorSubject, filter, Subject, Subscription } from "rxjs";
import {BaseSocketConfig, IBaseSocketConfig} from "./base-socket.config";
import * as Socket from 'socket.io-client';
import {SocketEmit, SocketEmitNowOrDiscard, SocketOn, TListenersDictionary} from "./base-socket-types";

export class BaseSocket<TListenersMap = any, TEmittersMap = any>
    implements
        SocketOn<TListenersMap>,
        SocketEmit<TEmittersMap>,
        SocketEmitNowOrDiscard<TEmittersMap>
    {

    protected listeners: TListenersDictionary<TListenersMap> = {};
    protected socketIO: Socket;
    protected config: BaseSocketConfig;

    private pConnected = new BehaviorSubject<boolean>(null);
    public $connected = this.pConnected.pipe(filter(val => val !== null));

    private subscription = new Subscription();
    constructor(config: IBaseSocketConfig) {
        this.config = new BaseSocketConfig(config);
        if (this.config.enabled) {
            this.connect();
        }
    }

    private mapSocketEventToSubject<Event extends keyof TListenersMap>(
        eventName: Event,
        subject: Subject<TListenersMap[Event]>
    ) {
        this.socketIO.on(eventName, data => {
            subject.next(data);
        });
    }

    private resubscribeToEvents() {
        Object.keys(this.listeners).forEach((key) => {
            const eventName = key as keyof TListenersMap;
            this.mapSocketEventToSubject(eventName, this.listeners[eventName]);
        });
    }

    private subscribeOnConnected() {
        this.socketIO.on('connect', () => this.pConnected.next(true));
        this.socketIO.on('disconnect', () => this.pConnected.next(false));
    }

    protected getListenerSubject<Event extends keyof TListenersMap>(event: Event): Subject<TListenersMap[Event]> {
        if (!this.listeners[event]) {
            const newSubject = new Subject<TListenersMap[Event]>();
            this.listeners[event] = newSubject;
            if (this.socketIO) {
                this.mapSocketEventToSubject(event, newSubject)
            }
        }
        return this.listeners[event];
    }

    get isConnected(): boolean {
        return this.pConnected.value;
    }

    disconnect() {
        if (this.socketIO) {
            this.socketIO.disconnect();
            this.socketIO.off();
            this.socketIO.close();
            this.socketIO = null;
            this.subscription.unsubscribe();
        }
    }

    connect() {
        if (!this.socketIO || !this.socketIO.connected) {
            this.disconnect();
            this.socketIO = Socket(this.config.connectionUrl, this.config.params);
            this.subscribeOnConnected();
            this.resubscribeToEvents();
        }
    }

    on<EventName extends keyof TListenersMap>(name: EventName) {
        return this.getListenerSubject<EventName>(name).asObservable();
    }

    emit<EventName extends keyof TEmittersMap>(event: EventName, data: TEmittersMap[EventName]): boolean {
        if (this.socketIO) {
            this.socketIO.emit(event, data);
            return true;
        }
        return false;
    }

    emitNowOrDiscard<EventName extends keyof TEmittersMap>(event: EventName, data: TEmittersMap[EventName]): boolean {
        if (this.isConnected) {
            return this.emit(event, data);
        }
        return false;
    }
}
