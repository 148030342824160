import {Injectable} from '@angular/core';
import {YhNavigationService} from '../../../services/navigation.service';
import {NavigationEnd, Resolve, Router} from '@angular/router';
import {filter, map, Observable, switchMap, take, tap} from 'rxjs';
import {Navigation} from '../../../../@fuse-demo-app/core/navigation/navigation.types';
import {CrmModuleInnerNavigation} from '../../../navigation/crm';
import {
    IYhFuseNavigationItem, IYhNavigation,
    TYhRoute,
    TYhRouteCombined
} from '../../../navigation/navigation-interfaces/navigation.interfaces';
import {AuthService} from '../../../services/auth.service';

interface IRoutesDictionary {
    [url: string]: IYhFuseNavigationItem;
}

@Injectable()
export class CrmNavigationService implements Resolve<Navigation> {
    
    private routesDict: IRoutesDictionary;
    
    static routesToCombinedRoutes(routes: TYhRoute[]): TYhRouteCombined[] {
        return routes as any;
    }

    constructor(private yhNavigationService: YhNavigationService,
                private authService: AuthService,
                private router: Router) {
        
        this.router.events.pipe(
            // user isAuthenticated and NavigationEnd event
            filter(ev => ev instanceof NavigationEnd && this.authService.isAuthenticated()),
            switchMap((ev: NavigationEnd) => {
                return this.getRouteByUrl(ev.urlAfterRedirects);
            }),
        ).subscribe(navItem => {
            if (navItem?.type === 'collapsable') {
                const redirectUrl = this.getChildToNavigate(navItem)?.link || '';
                this.router.navigate([redirectUrl], { replaceUrl: true });
            }
        });
    }
    
    private getChildToNavigate(collapsedItem: IYhFuseNavigationItem): IYhFuseNavigationItem | null {
        if (collapsedItem.children?.length) {
            for (const child of collapsedItem.children) {
                if (child.type !== 'collapsable') {
                    return child;
                }
                if (child.hidden && child.hidden()) {
                    continue;
                }
                return this.getChildToNavigate(child);
            }
        }
    }
    
    private getRouteByUrl(url: string): Observable<IYhFuseNavigationItem | null> {
        return this.yhNavigationService.navigation$.pipe(
            filter(v => !!v),
            take(1),
            map((nav) => {
                if (!this.routesDict) {
                    this.updateRoutesDictionary(nav.default);
                }
                return this.routesDict[url];
            })
        );
    }
    
    resolve(): Observable<IYhNavigation> {
        const routes = CrmNavigationService.routesToCombinedRoutes(CrmModuleInnerNavigation);
        return this.yhNavigationService.setModuleRoutes(routes)
            .pipe(tap((nav) => {
                this.updateRoutesDictionary(nav.default);
            }));
    }
    
    private updateRoutesDictionary(routes: IYhFuseNavigationItem[]) {
        const reduce = (arr: IYhFuseNavigationItem[] = [], dictionary: IRoutesDictionary) => {
            arr.forEach(route => {
                if (route.link) {
                    dictionary[route.link] = route;
                    reduce(route.children, dictionary);
                }
            });
            return dictionary;
        }
        this.routesDict = reduce(routes, {});
    }

}
