export class BaseSocketConfig {
    enabled: boolean;
    constructor(private config: IBaseSocketConfig) {
        this.enabled = this.config.enabled || false;
    }

    get connectionUrl() {
        const baseUrl = this.config.url.baseUrl;

        let extraUrlParts: string;
        if (this.config.url.extraUrlParts) {
            extraUrlParts = Object.keys(this.config.url.extraUrlParts)
                .map(extraKey => `${extraKey}=${this.config.url.extraUrlParts[extraKey]}`)
                .join('&');
        }

        return extraUrlParts ? `${baseUrl}?${extraUrlParts}` : baseUrl;
    }

    get params() {
        return this.config.params ? Object.assign({}, this.config.params) : null;
    }
}

type Transport = 'websocket' | 'flashsocket' | 'htmlfile' | 'xhr-polling' | 'jsonp-polling';

interface IExtraUrlParts {
    app_version: string;
    userId: string | number;
    token: string;
}

export interface IBaseSocketConfig {
    url: {
        baseUrl: string; // equal to : `${protocol}//${host}` OR `${protocol}//${host}:${port}`
        extraUrlParts?: Partial<IExtraUrlParts>;
    },
    params?: {
        transports?: Transport[]
    },
    enabled?: boolean;
}
