
export type ClassConstructorTypeOf<T> = new (...args:any[]) => T;

export default class BaseEntity {

    constructor(data: any = {}) {
        data = data && typeof data === 'object' ? data : {};
        Object.defineProperty( this['__proto__'], 'notPreparedData', {
            enumerable: true,
            configurable: true,
            get: function () { return data; },
            set: function (value) { data = value; }
        });
    }

    transformToArrEntity<Instance>(arr: any[], Entity: ClassConstructorTypeOf<Instance>): Array<Instance> {
        return (arr || []).map((item: any) => {
            return new Entity(item);
        });
    }

}
