import { TYhRoute } from '../../navigation-interfaces/navigation.interfaces';
import { PermissionsGuard } from '../../../core/guards/permissions.guard';
import { YhPermissions } from '../../../core/config/yh-permissions';

export enum SecurityNavigationEnum {
    root = 'security',
    groups = 'groups',
    permissions = 'permissions',
    operators = 'operators',
}

export const SecurityNavigation: TYhRoute[] = [
    {
        path: SecurityNavigationEnum.root,
        permissionFree: true,
        fuseNavConfig: {
            type: 'collapsable',
            title: 'Security',
            icon: 'security',
        },
        children: [
            {
                path: SecurityNavigationEnum.groups,
                loadChildren: () => import('app/modules/crm/components/security/security-groups/security-groups.module').then(m => m.SecurityGroupsModule),
                fuseNavConfig: {
                    title: 'Groups',
                    type: 'basic',
                },
                canActivate: [PermissionsGuard],
                data: {
                    permission: YhPermissions.security.groups.root,
                }
            },
        ]
    }
];
