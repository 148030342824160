import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor() {
    }

    getStorageByType(type: 'local' | 'session' = 'local'): Storage {
        return type === 'session' ? sessionStorage : localStorage;
    }

    set(key: string, data: string | number | any, type?: 'local' | 'session') {
        const storage = this.getStorageByType(type);
        if (typeof data !== "string") {
            data = JSON.stringify(data);
        }
        storage.setItem(key, data);
    }

    get(key: string, type?: 'local' | 'session') {
        const storage = this.getStorageByType(type);
        const data: any = storage.getItem(key);
        try {
            const preparedData = JSON.parse(data);
            if (!isNaN(+preparedData)) {
                return +preparedData;
            }
            return preparedData;
        } catch (error: any) {
        }
        return data;
    }

    remove(key: string, type?: 'local' | 'session') {
        const storage = this.getStorageByType(type);
        storage.removeItem(key);
    }

}