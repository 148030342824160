export const YhPermissions = {
    security: {
        permissions: 'permissions',
        groups: {
            root: 'security.groups',
    
            create: 'security.groups.create',
            delete: 'security.groups.delete',
            edit: 'security.groups.edit',
            editProtected: 'security.groups.edit_protected',
            members: 'security.groups.members',
        }
    },
    users: {
        root: 'security.users',
    
        view: 'security.users.view',
        edit: 'security.users.edit',
        create: 'security.users.create',
        delete: 'security.users.delete',
        disable: 'security.users.disable',
    
        addPersonalTelegramPhones: 'security.users.add-personal-telegram-phones',
        addPersonalWhatsappPhones: 'users.add-personal-whatsapp-phones',
    },

    newsFeed: {
        root: 'settings.news-feed',

        view: 'settings.news-feed.readers.view',
        edit: 'settings.news-feed.readers.edit',
        create: 'settings.news-feed.readers.create',
        delete: 'settings.news-feed.readers.delete'
    }
} as const;
