import {Injectable} from "@angular/core";
import {AuthService} from "./auth.service";
import {NavigationService} from "../../@fuse-demo-app/core/navigation/navigation.service";
import {BehaviorSubject, filter, map, Observable, take} from "rxjs";
import OperatorEntity from "../entity/operator.entity";
import {
    IYhFuseNavigationItem, IYhNavigation,
    TYhRouteCombined,
} from "../navigation/navigation-interfaces/navigation.interfaces";
import {cloneDeep} from 'lodash-es';

class YhFuseNavItem {

    static FilterFuseRoutes(routes: TYhRouteCombined[], operator: OperatorEntity) {
        return routes.filter(route => {
            if (route.fuseNavConfig) {
                // is used in Fuse navigation
                if (route.permissionFree) {
                    // no need to check permissions
                    return true;
                }
                // check permissions
                const { permission, permissionCheck, permissions } = route.data || { };
                if (permissions && permissionCheck) {
                    // multiple permissions
                    return operator.hasPermissions(permissions, permissionCheck);
                }
                // single permission
                return operator.hasPermission(permission);
            }
        });
    }

    static ParseFromRouteArray(routes: TYhRouteCombined[], operator: OperatorEntity, parent: YhFuseNavItem = null) {
        const filtered = YhFuseNavItem.FilterFuseRoutes(routes, operator);
        return filtered.map(route => new YhFuseNavItem(operator, route, parent).navItem);
    }

    constructor(private operator: OperatorEntity,
                private item: TYhRouteCombined,
                private parent: YhFuseNavItem = null) { }

    get navItem(): IYhFuseNavigationItem {
        const item: IYhFuseNavigationItem = cloneDeep(this.item.fuseNavConfig);
        item.link = item.link || this.fuseLink;

        if (this.item.children) {
            item.children = YhFuseNavItem.ParseFromRouteArray(this.item.children || [], this.operator, this);
        }
        
        if (item.type === 'collapsable') {
            item.hidden = () => {
                if (!item.children) {
                    // no children, should be hidden
                    return true;
                }
                const hasVisibleChild = item.children.find(item => item.hidden ? !item.hidden() : true);
                // has no visible child
                return !hasVisibleChild;
            };
        }
        

        return item;
    }

    get isRoot() {
        return !this.parent;
    }

    get path() {
        const path = this.item.fuseNavConfig.replacePathWith || this.item.path;
        if (this.isRoot) {
            return `/${path}`;
        }
        return path;
    }
    
    private get fuseLink() {
        if (this.item.fuseNavConfig.link) {
            return this.item.fuseNavConfig.link;
        }

        const pathArray = [this.path];
        let parent = this.parent;
        while (parent) {
            pathArray.unshift(parent.path);
            parent = parent.parent;
        }
        return pathArray.join('/');
    }
}

const EmptyNavigation: IYhNavigation = {
    // in order to keep the Fuse source code as is, keep Navigation interface
    default: [], // only the "default" property will be used in YH
    compact: null,
    futuristic: null,
    horizontal: null,
};

@Injectable()
export class YhNavigationService extends NavigationService {

    private navigation = new BehaviorSubject<IYhNavigation>(null);
    private $navigation = this.navigation.pipe(filter(nav => !!nav));

    constructor(
        private authService: AuthService,
    ) {
        super(null); // no need to pass httpClient - get() method is overridden, and doesn't use httpClient
    }

    // override base class getter
    get navigation$(): Observable<IYhNavigation> {
        return this.$navigation;
    }

    // override base class method
    get(): Observable<IYhNavigation> {
        return this.$navigation;
    }
    
    setModuleRoutes(routes: TYhRouteCombined[]): Observable<IYhNavigation> {
        return this.authService.$operator.pipe(
            filter(o => !!o),
            take(1),
        ).pipe(
            map(operator => {
                return this.prepareNavigation(operator, routes);
            })
        );
    }

    private prepareNavigation(operator: OperatorEntity, routes: TYhRouteCombined[]) {
        const roots = YhFuseNavItem.ParseFromRouteArray(routes, operator);

        const nav: IYhNavigation = {
            ...EmptyNavigation,
            default: roots
        };

        this.navigation.next(nav);
        return nav;
    }
}
