import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from "../@fuse/services/config";
import { ServiceLocator } from "./core/serviceLocator";
import { LayoutModule } from "app/layout/layout.module";
import { CoreModule } from "app/core/core.module";
import { AppComponent } from 'app/app.component';
import { AppRouting, RouterDefaultConfig } from "./navigation/navigation";
import { appConfig } from "app/core/config/app.config";

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(AppRouting, RouterDefaultConfig),
        // Fuse
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        // Layout module of your application
        LayoutModule,
        // Core module of your application
        CoreModule
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {

    constructor(private injector: Injector){    // Create global Service Injector.
        ServiceLocator.injector = this.injector;
    }

}
