import {environment} from "../../environments/environment";
import {Injectable} from "@angular/core";

const debugMode = environment.debugMessages;
type TLogLevel = 'log' | 'error';

@Injectable()
export class LoggerService {

    get log() {
        return this.dispatch('log');
    }

    get error() {
        return this.dispatch('error');
    }

    dispatch(logLevel: TLogLevel) {
        if (debugMode) {
            return console[logLevel].bind(console);
        } else {
            return this.dummy;
        }
    }

    dummy = (): any => undefined;
}
