import { AuthGuard } from "../../core/guards/auth.guard";
import { TYhRoute } from "../navigation-interfaces/navigation.interfaces";
import { LayoutComponent } from '../../layout/layout.component';
import ProfileNavigation from './profile/profile.navigation';
import { SettingsNavigation } from './settings/settings.navigation';
import { CrmNavigationService } from '../../modules/crm/services/crm-navigation.service';
import { ContactsNavigation } from './contacts/contacts.navigation';
import { ClientNavigation } from './client/client.navigation';
import { SecurityNavigation } from './security/security.navigation';

export const CrmModulePath = 'crm';

export const CrmModuleDefaultRoute: TYhRoute = {
    path: CrmModulePath,
    canActivate: [AuthGuard],
    loadChildren: () => import('app/modules/crm/crm.module').then(m => m.CrmModule),
    permissionFree: true,
};

export const CrmModuleInnerNavigation: TYhRoute[] = [
    {
        path: '',
        resolve: {
            navigation: CrmNavigationService,
        },
        canActivate: [AuthGuard],
        component: LayoutComponent,
        permissionFree: true,
        data: {
            layout: 'dense',
        },
        fuseNavConfig: {
            type: "group",
            title: 'Applications',
            replacePathWith: CrmModulePath,
        },
        children: [
            {
                path: '',
                pathMatch : 'full',
                redirectTo: 'profile',
                permissionFree: true,
            },
            ...ContactsNavigation,
            ...ProfileNavigation,
            ...SettingsNavigation,
            ...ClientNavigation,
            ...SecurityNavigation,
        ]
    }
];
