import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    MatMomentDateModule
} from '@angular/material-moment-adapter';
import { YhMatDateFormats } from './date-formats';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MatNativeDateModule,
        MatMomentDateModule,
    ],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: YhMatDateFormats },
        { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    ],
})
export class YhDateModule { }
