import {HttpClient, HttpParams, HttpParamsOptions} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {ServiceLocator} from "../../core/serviceLocator";
import {Observable} from "rxjs";

export class BaseRestService {

    constructor(private http: HttpClient = ServiceLocator.injector.get(HttpClient),
                private apiUrl: string = environment.rest.apiUrl) {
    }

    protected getRestEndpoint(endpoint: string): string {
        if (endpoint.charAt(0) === '/') {
            return `${this.apiUrl}${endpoint}`;
        }
        return endpoint;
    }

    protected post<R, D>(endpoint: string, body: D | null, options?): Observable<R> {
        return this.http.post(this.getRestEndpoint(endpoint), body, options) as Observable<any>;
    }

    protected get<R = any>(endpoint: string, options?): Observable<R> {
        return this.http.get(this.getRestEndpoint(endpoint), options) as Observable<any>;
    }

}
