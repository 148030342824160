import BaseEntity from '../../../../../entity/@base.entity';
import { ClientEntity } from './client.entity';

type TClientRef = Pick<ClientEntity, 'userId' | 'projectId'>;
export type TClientRefData = { [K in keyof TClientRef]: string | number }

export class ClientRefEntity extends BaseEntity implements TClientRef {
    
    userId: number;
    projectId: number;
    
    static fromRouteParams(params) {
        return new ClientRefEntity(params);
    }
    
    constructor(data: TClientRefData) {
        super(data);
        
        this.userId = +data?.userId;
        this.projectId = +data?.projectId;
        
    }
    
    equalTo(data: TClientRefData) {
        const { userId, projectId } = new ClientRefEntity(data);
        return this.userId === userId && this.projectId === projectId;
    }
    
}
