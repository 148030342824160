<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img
            class="w-7 h-7 rounded-full"
            *ngIf="showAvatar && user.photo"
            [src]="USERS_PHOTOS_DOMAIN + user.photo">

        <mat-icon
            *ngIf="!showAvatar || !user.photo"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>

        <mat-icon class="current-status-icon absolute -right-1 -bottom-1 rounded-full"
                  *ngIf="operatorStatus; else unknownUserStatus"
                  [ngStyle]="{'color': operatorStatus.color}"
        >
            {{operatorStatus.icon}}
        </mat-icon>
        <ng-template #unknownUserStatus>
            <div class="absolute left-0 bottom-0 w-full">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </ng-template>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Signed in as</span>
            <span class="mt-1.5 text-md font-medium">{{user.email}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item [routerLink]="['/crm/profile']">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
    </button>
    <button
        mat-menu-item
        [matMenuTriggerFor]="userStatus">
        <mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'"></mat-icon>
        <span>Status</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>

<mat-menu
    class="user-status-menu"
    #userStatus="matMenu">
    <ng-template #statusItem let-status>
        <button *ngIf="!status.hidden"
                mat-menu-item
                (click)="updateUserStatus(status.statusId)">
            <mat-icon class="w-4 h-4 mr-3 rounded-full"
                      [ngStyle]="{'color': status.color}">
                {{status.icon}}
            </mat-icon>
            <span>{{status.title}}</span>
        </button>
    </ng-template>
    <ng-container *ngFor="let item of operatorStatusList">
        <ng-container *ngTemplateOutlet="statusItem; context: {$implicit: item}"></ng-container>
    </ng-container>
</mat-menu>
