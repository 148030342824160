import BaseEntity from "./@base.entity";

export default class GroupEntity extends BaseEntity {

    email: string;
    name: string;
    permissions: string[];

    constructor(data: any = {}) {
        super(data);
        this.email = data.email;
        this.name = data.name;
        this.permissions = data.permissions || [];
    }

}
