<yhelper-background>
    <div class="h-40 lg:h-60"></div>
    <!-- User info -->
    <div class="flex flex-col flex-0 lg:flex-row items-center max-w-5xl w-full mx-auto px-8 lg:h-18 bg-card">

        <!-- Avatar -->
        <div class="w-32 h-32 -mt-26 lg:-mt-22 rounded-full relative">
                    <span class="current-avatar w-32 h-32 rounded-full ring-4 ring-bg-card"
                          [style]="{
                            backgroundImage: operator?.photo ? 'url(' + (USERS_PHOTOS_DOMAIN + operator?.photo) + ')' : ''
                         }"
                    >
                    </span>
        </div>

        <!-- Details -->
        <div class="flex flex-col items-center lg:items-start mt-4 lg:mt-0 lg:ml-8">
            <div class="text-lg font-bold leading-none mb-2">{{operator?.name}}</div>
            <div>
                <span class="font-semibold">Email</span>
                <span class="text-secondary"> : {{operator?.email}}</span>
            </div>
            <div>
                <span class="font-semibold">Gender</span>
                <span class="text-secondary"> : {{operator?.gender}}</span>
            </div>
        </div>

        <!-- Separator -->
        <div class="hidden lg:flex h-8 mx-8 border-l-2"></div>

        <!-- Stats -->
        <div class="flex items-center mt-6 lg:mt-0 space-x-6">
            <div class="flex flex-col items-center">
                <span class="font-bold">Group</span>
                <span class="text-sm font-medium text-secondary">{{operator?.group?.name}}</span>
            </div>
            <div class="flex flex-col items-center">
                <span class="font-bold">Phone</span>
                <span class="text-sm font-medium text-secondary">{{operator?.phone}}</span>
            </div>
        </div>

        <ng-content ngProjectAs="[menu]"></ng-content>

    </div>
</yhelper-background>