<div class="flex flex-col flex-auto min-w-0 container">

    <!-- Header -->
    <div class="flex flex-col shadow relative">

        <operator-profile-header [operator]="operator">
            <ng-container menu>
                <div class="flex items-center mt-8 mb-4 lg:m-0 lg:ml-auto space-x-6">
                    <a class="font-medium" *ngFor="let item of profileMenu"
                       [routerLinkActive]="'font-semibold text-primary'"
                       [routerLink]="[item.path]">
                        {{item.name}}
                    </a>
                </div>
            </ng-container>
        </operator-profile-header>

    </div>

    <!-- Main -->
    <div class="flex flex-auto justify-center w-full max-w-5xl mx-auto p-6 sm:p-8">
        <router-outlet></router-outlet>
    </div>

</div>
