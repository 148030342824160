import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, filter, Observable, tap, throwError } from 'rxjs';
import { AuthService } from 'app/services/auth.service';
import { Router } from '@angular/router';
import { RoutesHelper } from '../helpers/routes.helper';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService,
                private router: Router,
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request object
        let cloneRequest = req.clone();
        const token = this.authService.token;
        if (token) {
            cloneRequest = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${token}`)
            });
        }

        // Response
        return next.handle(cloneRequest).pipe(
            tap({
                error: (error) => {
                    if (error instanceof HttpErrorResponse && error.status === 401) {
                        const isSignInPage = this.router.url.toLowerCase().indexOf(RoutesHelper.SignIn.toLowerCase()) === 0;
                        !isSignInPage && this.authService.signOut(true);
                    }
                }
            })
        );
    }
}
