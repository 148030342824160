import {Injectable} from '@angular/core';
import {CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {PermissionsService} from '../../services/permissions.service';
import {YhActivatedRouteSnapshot} from '../../navigation/navigation-interfaces/navigation.interfaces';

@Injectable({
    providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
    
    constructor(private permissionsService: PermissionsService,
                private router: Router) { }
    
    canActivate(route: YhActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        if (route.routeConfig.permissionFree) {
            // no need to check permissions
            return true;
        }
        
        const { data } = route;
        let allowedRoute = false;

        if (data.permissions) {
            allowedRoute = this.permissionsService.hasPermissions(data.permissions, data.permissionCheck);
        } else if (data.permission) {
            allowedRoute = this.permissionsService.hasPermission(data.permission);
        }
        
        if (!allowedRoute) {
            // go to app start route
            this.router.navigate(['']);
        }
        
        return allowedRoute;
    }
}
