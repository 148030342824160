<!-- ClientSearch search -->
<ng-container *ngIf="appearance === 'clientSearch'">
    <button
        mat-icon-button
        *ngIf="!opened"
        (click)="open()"
        class="skip-clicks-inside"
    >
        <mat-icon [svgIcon]="'heroicons_outline:search'"></mat-icon>
    </button>
    <div
        class="absolute inset-0 flex items-center flex-shrink-0 z-99 bg-card"
        *ngIf="opened"
        [excludeClicks]="['skip-clicks-inside']"
        (clickOutside)="close()"
        @slideInTop
        @slideOutTop>
        <mat-icon
            class="absolute ml-6 sm:ml-8"
            [svgIcon]="'heroicons_outline:search'"></mat-icon>
        <input
            class="w-full h-full px-16 sm:px-18"
            [formControl]="searchControl"
            [placeholder]="'Search'"
            (keydown)="onKeydown($event)"
            (keydown.enter)="this.searchByModeIndex(selectedModeIndex)"
            #clientSearchInput>

        <button
            class="absolute top-1/2 right-5 sm:right-7 flex-shrink-0 w-10 h-10 -mt-5"
            mat-icon-button
            (click)="close()">
            <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <div *ngIf="opened" class="search-mode-list absolute top-full left-0 w-full flex flex-col items-start z-99 bg-card"
         @slideInTop
         @slideOutTop
    >
        <div *ngFor="let queryMode of queryForView; index as i"
             class="search-mode-list-item w-full px-5 py-3 cursor-pointer"
             (click)="searchByModeIndex(i)"
             [ngClass]="{'bg-gray-100': i === selectedModeIndex}">
            <span>{{queryMode.title}}</span>
        </div>
    </div>
</ng-container>

<!-- Basic search -->
<ng-container *ngIf="appearance === 'basic'">
    <div class="w-full sm:min-w-80">
        <mat-form-field class="fuse-mat-no-subscript w-full">
            <mat-icon
                matPrefix
                [svgIcon]="'heroicons_outline:search'"></mat-icon>
            <input
                matInput
                [formControl]="searchControl"
                [placeholder]="'Search'"
                (keydown)="onKeydown($event)">
        </mat-form-field>
    </div>
</ng-container>
