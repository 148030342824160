const {protocol} = window.location;

export const environment = {
    production: false,
    debugMessages: true,
    rest: {
        apiUrl: `${protocol}//rest.dev.yhelper.net/api`,
        images: {
            users: `${protocol}//frontend-v3.dev.yhelper.net/photos/`,
            projectsLogo: `${protocol}//frontend-v3.dev.yhelper.net/project-logo/`
        }
    },
    notifierConfig: {
        host: 'dev-notifier.yhelper.net',
        protocol,
        enabled: true,
    },
    chatConfig: {
        protocol,
        host: 'chat.dev.yhelper.net/operators',
        clientHost: 'chat.dev.yhelper.net',
        enabled: true,
        removeClosedChat: false,
        debugMessages: true,
        debugHostUrl: false
    },
    viberClient: {
        serverUrl: 'https://dev-viber.yhelper.net'
    },
    tinymce: {
        apiKey: 'bup7sz973ah5sox3jv6qsjbgfr01e9dyxsjvubd8oxm2oa6b',
        deprecation_warnings: true,
    },
    baseImgUrl: '/',
};
