import {TYhRoute} from '../../../navigation-interfaces/navigation.interfaces';
import {PermissionsGuard} from '../../../../core/guards/permissions.guard';

export const SettingsAppConfigNavigation: TYhRoute[] = [
    {
        path: 'app-config',
        loadChildren: () => import('app/modules/crm/components/settings/app-config/app-config.module').then(m => m.AppConfigModule),
        fuseNavConfig: {
            title: 'App config',
            type: 'basic',
        },
        data: {
            permission: 'admin.app.config',
        },
        canActivate: [PermissionsGuard],
    }
];
