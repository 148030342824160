import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import OperatorEntity from "../entity/operator.entity";
import { environment } from "../../environments/environment";
import { TransportSocketService } from "../core/sockets/transport-socket.service";
import {
    ChatSocketExceptionEvents,
    ChatSocketEmitEvents,
    ChatSocketListenEvents
} from "../core/sockets/chat-socket/chat-socket-events";
import {SharedWorkerService} from "./shared-worker.service";
import { BehaviorSubject, map, merge } from "rxjs";
import { OperatorStatusEnum } from "../core/enums/operator-status.enum";
import { IBaseSocketConfig } from "../core/sockets/base-socket/base-socket.config";
import { ClientEntity } from '../modules/crm/components/client/client-types/client.entity';
import { ChatRestService } from './rest/chat.rest.service';
import { ChatFormEntity } from '../entity/chat/chat-form.entity';
import { ClientRefEntity, TClientRefData } from '../modules/crm/components/client/client-types/client-ref.entity';
import { YhToastrService } from '../components/yh-toastr/yh-toastr.service';

const ChatSocketConfig: IBaseSocketConfig = {
    url: {
        baseUrl: `${environment.chatConfig.protocol}//${environment.chatConfig.host}`,
    },
    params: {
        transports: ["websocket", "flashsocket", "htmlfile", "xhr-polling", "jsonp-polling"]
    },
    enabled: environment.chatConfig.enabled,
};

const chatDebugMessages = environment.chatConfig.debugMessages;

@Injectable()
export class ChatService extends TransportSocketService<ChatSocketListenEvents, ChatSocketEmitEvents, ChatSocketExceptionEvents> {

    private operatorStatusChange = new BehaviorSubject(OperatorStatusEnum.Unknown);
    $onOperatorStatusChange = this.operatorStatusChange.asObservable();

    constructor(private authService: AuthService,
                private chatRestService: ChatRestService,
                private yhToastrService: YhToastrService,
                sharedWorkerService: SharedWorkerService,
                
    ) {
        super('ChatSocket', sharedWorkerService, chatDebugMessages);
        this.logServiceName = 'Chat';
        this.authService.$operator.subscribe(this.onOperatorLoaded.bind(this));
    }
    
    getViberInvitationLink({ userId, projectId }: Pick<ClientEntity, 'userId' | 'projectId'>) {
        return this.chatRestService.getViberInvitationLink(userId, projectId);
    }
    
    getChatForms() {
        return this.chatRestService.getChatForms().pipe(
            map((forms) => forms.map(form => new ChatFormEntity(form, true)))
        );
    }
    
    deleteDeferredMessage(chat_id: number, comment: string) {
        this.emit('operator:chat.deleteDeferred', { chat_id, comment });
    }
    
    getDeferredMessages(params: TClientRefData) {
        const { userId, projectId } = new ClientRefEntity(params)
        return this.chatRestService.getDefMessages({userId, siteId: projectId});
    }

    private onOperatorLoaded(operator: OperatorEntity | null) {
        if (operator) {

            const config: IBaseSocketConfig = Object.assign({}, ChatSocketConfig);
            config.url.extraUrlParts = {
                token: encodeURIComponent(operator.token),
                userId: operator.id,
            };

            super.connectSocket(config);
            this.addListeners();
        }
    }

    private addListeners() {
        merge(
            this.on('connected').pipe(map(d => d.user)),
            this.on('operator:update'),
            this.on('operator:chat.status'),
        ).subscribe((data) => this.operatorStatusChange.next(data?.status_id));
        
        this.$connected.subscribe(connected => {
            if (connected) {
                this.yhToastrService.show('Chat connection established').success();
            } else {
                this.yhToastrService.show('Chat connection lost').error({ duration: 4000 });
            }
        });
        
        this.addExceptionsListener();
    }
    
    private addExceptionsListener() {
        this.on('exception').subscribe(error => {
            // TODO: implement exception notifications
            console.warn(error);
            // this.toastersService.showErrorMessage(error);
            // const eventName = error.event.split('.').pop();
            // const capitalizedEventName = eventName.charAt(0).toUpperCase() + eventName.substr(1);
            // const subjectName = `onChat${capitalizedEventName}`;
            // const messageSubject = this.eventBehaviorSubjectsList[subjectName];
            // const eventSubject = this.eventBehaviorSubjectsList[`${subjectName}Event`];
            // messageSubject && messageSubject.next(error.message);
            // eventSubject && eventSubject.next(error);
        })
    }

}
