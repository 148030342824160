import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {debounceTime, Subject, takeUntil} from 'rxjs';
import {AuthService} from "../../services/auth.service";
import OperatorEntity from "../../entity/operator.entity";
import {ChatService} from "../../services/chat.service";
import {OperatorStatusEnum} from "../../core/enums/operator-status.enum";
import {environment} from "../../../environments/environment";


interface IOperatorStatus {
    statusId: OperatorStatusEnum,
    color: string;
    title: string,
    icon: string;
    hidden?: boolean;
    permission?: string;
}

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserComponent implements OnInit, OnDestroy {

    @Input() showAvatar: boolean = true;

    readonly USERS_PHOTOS_DOMAIN = environment.rest.images.users;
    user: OperatorEntity = new OperatorEntity();
    operatorStatusList: IOperatorStatus[];
    operatorStatus: IOperatorStatus;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private changeDetectorRef: ChangeDetectorRef,
                private authService: AuthService,
                private chatService: ChatService) {
        this.operatorStatusList = [
            {
                title: 'Offline',
                statusId: OperatorStatusEnum.Offline,
                hidden: true,
                icon: 'radio_button_unchecked',
                color: '#616161'
            },
            {
                title: 'Online',
                statusId: OperatorStatusEnum.Online,
                icon: 'check_circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                statusId: OperatorStatusEnum.Away,
                icon: 'access_time',
                color: '#FFC107'
            },
            {
                title: 'Pending',
                statusId: OperatorStatusEnum.Pending,
                hidden: true,
                icon: 'more_horiz',
                color: '#616161'
            },
            {
                title: 'Invisible',
                statusId: OperatorStatusEnum.Invisible,
                icon: 'radio_button_unchecked',
                color: '#BDBDBD',
                permission: 'settings.status.invisible'
            }
        ];
    }

    ngOnInit(): void {
        this.user = this.authService.operator;
        this.chatService.$onOperatorStatusChange
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(100),
            )
            .subscribe(status => this.setOperatorStatus(status));
    }

    private setOperatorStatus(status: OperatorStatusEnum) {
        if (this.operatorStatus?.statusId !== status) {
            this.operatorStatus = this.operatorStatusList.find(item => item.statusId === status);
            this.changeDetectorRef.detectChanges();
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    updateUserStatus(status: OperatorStatusEnum): void {
        this.chatService.emit('operator:chat.status', status);
    }

    signOut(): void {
        this.authService.signOut(true);
    }
}
