import {BaseSocket} from "./base-socket/base.socket";
import {SharedWorkerService} from "../../services/shared-worker.service";
import {IBaseSocketConfig} from "./base-socket/base-socket.config";
import { filter, Observable, tap } from "rxjs";
import {AvailableSocketNames} from "./library/library.sockets";
import {
    SocketEmit,
    SocketOn,
    TListenerExceptionExtension,
    TSocketEmitException
} from "./base-socket/base-socket-types";
import {LoggerService} from "../../services/logger.service";


export class TransportSocketService<Listeners extends TListenerExceptionExtension<ExceptionEvents>, Emitters, ExceptionEvents> implements SocketOn<Listeners>, SocketEmit<Emitters> {
    
    private socket: BaseSocket<Listeners, Emitters>;
    protected logServiceName: string;
    protected loggerService = new LoggerService();
    constructor(private socketTypeName: AvailableSocketNames,
                protected sharedWorkerService: SharedWorkerService,
                private logMessages = true) {
    }

    private onUsingSocket(eventName) {
        if (!this.socket) {
            this.throwError('onUsingSocket', 'Socket is not defined');
        }
        return this.socket.on(eventName);
    }

    private onUsingWorker(eventName) {
        return this.getSharedWorkerSocket().on(eventName).asObservable();
    }

    private emitUsingSocket(eventName, data) {
        if (!this.socket) {
            this.throwError('emitUsingSocket', 'Socket is not defined');
        }
        return this.socket.emit(eventName, data);
    }

    private emitUsingWorker(eventName, data) {
        return this.getSharedWorkerSocket().emit(eventName, data);
    }

    private getSharedWorkerSocket() {
        const socketTransport = this.sharedWorkerService.socket<Listeners, Emitters>(this.socketTypeName);
        if (!socketTransport) {
            this.throwError('onUsingWorker', 'Socket is not defined');
        }
        return socketTransport;
    }

    private throwError(method: string, message: string) {
        throw new Error(`${this.constructor.name}: (${method}()) _ Error: ${message}`);
    }

    get transportMode(): 'Socket' | 'SW' {
        return this.sharedWorkerService.sharedWorkerAvailable ? "SW" : "Socket";
    }

    get $connected() {
        return this.sharedWorkerService.sharedWorkerAvailable
            ? this.getSharedWorkerSocket().$connected
            : this.socket.$connected;
    }

    connectSocket(config: IBaseSocketConfig) {
        const conf = Object.assign({}, config, {enabled: true} as Partial<IBaseSocketConfig>);
        if (this.sharedWorkerService.sharedWorkerAvailable) {
            this.sharedWorkerService.connectSocket<Listeners, Emitters>(this.socketTypeName, conf);
        } else {
            this.socket = new BaseSocket<Listeners, Emitters>(conf);
        }

        // log socket connection state change
        if (this.logMessages) {
            // log pipe
            const serviceName = this.logServiceName || this.constructor.name;
            this.$connected.subscribe(state => {
                const clr = state ? '#10BD00' : '#F30000';
                this.loggerService.log(`%c${serviceName} socket connection state: ${state}`, `color: ${clr}`);
            });
        }
    }
    
    onException<ExceptionName extends keyof ExceptionEvents>(exceptionName: ExceptionName) {
        type TSpecificExceptionType = TSocketEmitException<ExceptionEvents, ExceptionName>;
        return this.on('exception')
            .pipe(filter(evData => evData.event === exceptionName)) as Observable<TSpecificExceptionType>;
    }

    on<EventName extends keyof Listeners>(eventName: EventName): Observable<Listeners[EventName]> {

        let result: Observable<Listeners[EventName]>;
        if (this.sharedWorkerService.sharedWorkerAvailable) {
            result = this.onUsingWorker(eventName);
        } else {
            result = this.onUsingSocket(eventName);
        }

        if (this.logMessages) {
            // log pipe
            const serviceName = this.logServiceName || this.constructor.name;
            return result.pipe(
                tap(data => {
                    this.loggerService.log(`%c${serviceName} ON: %c${eventName}`, 'color: #699416', 'color: #fb6016', data);
                })
            );
        }
        return result;
    }

    emit<EventName extends keyof Emitters>(event: EventName, data: Emitters[EventName]): boolean {
        let emitResult: boolean;

        if (this.sharedWorkerService.sharedWorkerAvailable) {
            emitResult = this.emitUsingWorker(event, data);
        } else {
            emitResult = this.emitUsingSocket(event, data);
        }
        // log
        if (this.logMessages) {
            const serviceName = this.logServiceName || this.constructor.name;
            const emitResLog = emitResult ? '' : ' #FAILED!';
            this.loggerService.log(`%c${serviceName} EMIT${emitResLog}: %c${event}`, 'color: #941616', 'color: #fb6016', data);
        }
        return emitResult;
    }
}
