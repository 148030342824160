import {Injectable} from '@angular/core';
import {IWorkerMessage,} from "../shared-worker/worker-message";
import {AuthService} from "./auth.service";
import {SocketEmit, SocketOn, TListenersDictionary} from "../core/sockets/base-socket/base-socket-types";
import {AvailableSocketNames} from "../core/sockets/library/library.sockets";
import {IBaseSocketConfig} from "../core/sockets/base-socket/base-socket.config";
import {BehaviorSubject, filter, Subject} from "rxjs";
import {WorkerMessageType} from "../shared-worker/worker-message-type.enum";
import {IWMSocketEventOccurredData} from "../shared-worker/worker-messages-data/wm-socket-event-occurred.data";
import {IWorkerMessageSocketCreateData} from "../shared-worker/worker-messages-data/wm-create-socket.data";
import {IWMSocketEmitData} from "../shared-worker/worker-messages-data/wm-socket-emit.data";
import {IWMSocketConnectedData} from "../shared-worker/worker-messages-data/wm-socket-connected.data";

@Injectable({
  providedIn: 'root'
})
export class SharedWorkerService {

  private workerSockets: {[key in AvailableSocketNames]?: TransportThroughSharedWorkerSocket<any, any>} = {};
  private worker: SharedWorker;
  private isWorkerAvailable = !!window['SharedWorker'];
  constructor(private authService: AuthService) {
    window.addEventListener('beforeunload', () => {
      this.stopWorker();
    });
    this.authService.$operator
        .pipe(filter(() => !this.worker && this.authService.isAuthenticated()))
        .subscribe(() => this.startWorker());
  }

  get sharedWorkerAvailable(): boolean {
    return !!this.isWorkerAvailable;
  }

  connectSocket<Listeners, Emitters>(name: AvailableSocketNames, conf: IBaseSocketConfig) {
    if (!this.workerSockets[name]) {
      this.workerSockets[name] = new TransportThroughSharedWorkerSocket<Listeners, Emitters>(name, this.worker, conf);
    }
    return this.workerSockets[name];
  }

  socket<Listeners, Emitters>(name: AvailableSocketNames): TransportThroughSharedWorkerSocket<Listeners, Emitters> {
    return this.workerSockets[name];
  }

  startWorker() {
    this.stopWorker();
    if (this.sharedWorkerAvailable) {
      this.worker = new SharedWorker(new URL('.././shared.worker', import.meta.url), {
        name: 'yh-shared-worker',
        type: 'module',
      });
      this.worker.port.addEventListener("message", e => {
        this.handleMessageFromWorker(e);
      });
      this.worker.port.addEventListener("messageerror", e => {
        this.handleErrorMessageFromWorker(e);
      });
      this.worker.port.start();
    } else {
      // SharedWorker is not supported
    }
  }

  stopWorker(logout?: boolean) {
    if (this.worker) {
      if (logout) {
        // trigger logging out in other tabs
        this.postMessage({type: WorkerMessageType.Logout});
      }
      this.postMessage({type: WorkerMessageType.Close});
      this.worker.port.close();
      this.worker = null;
    }
    this.workerSockets = {};
  }

  postMessage<T = any>(message: IWorkerMessage<T>): boolean {
    if (this.worker?.port?.postMessage) {
      this.worker?.port.postMessage(message);
      return true;
    }
  }

  private handleMessageFromWorker(e: MessageEvent<IWorkerMessage>) {
    const message = e.data || {} as IWorkerMessage;
    switch (message.type) {
      case WorkerMessageType.Logout:
        this.authService.signOut(true);
        break;
      case WorkerMessageType.SocketEventOccurred:
      case WorkerMessageType.SocketConnectedState:
        // these types are handled by this.workerSockets['SOCKET_NAME'] // TransportThroughSharedWorkerSocket
        break;
      default:
      console.log(message.type);
      console.log(message.data);
    }
  }

  private handleErrorMessageFromWorker(e: MessageEvent<IWorkerMessage>) {
    console.log('handleErrorMessageFromWorker', e);
  }

}

//////////// TransportThroughSharedWorkerSocket
class TransportThroughSharedWorkerSocket<Listeners, Emitters> implements SocketOn<Listeners>, SocketEmit<Emitters> {

  protected listeners: TListenersDictionary<Listeners> = {};
  protected _connected = new BehaviorSubject<boolean>(null);
  $connected = this._connected.pipe(filter(val => val !== null));

  constructor(private socketName: AvailableSocketNames,
              private worker: SharedWorker,
              socketConfig: IBaseSocketConfig
              ) {
    // add listener
    this.worker.port.addEventListener("message", msg => {
      this.handleMessageFromWorker(msg);
    });
    // post message - create socket connection
    this.postMessage<IWorkerMessageSocketCreateData>({
      type: WorkerMessageType.CreateSocket,
      data: { socketName, socketConfig }
    });
  }

  private handleMessageFromWorker(msg: MessageEvent<IWorkerMessage>) {
    const body = msg.data;
    switch (body.type) {
      case WorkerMessageType.SocketConnectedState:
        const {connected} = body.data as IWMSocketConnectedData;
        this._connected.next(connected);
        break;
      case WorkerMessageType.SocketEventOccurred:
        this.onSocketEventOccurred(body.data);
        break;
      default:
        // other types should not be handled
    }
  }

  private postMessage<T = any>(message: IWorkerMessage<T>) {
    this.worker.port.postMessage(message);
  }

  private onSocketEventOccurred({eventData, socketEvent}: IWMSocketEventOccurredData<Listeners>) {
    const eventSubject = this.listeners[socketEvent];
    if (eventSubject) {
      eventSubject.next(eventData);
    }
  }

  on<EventName extends keyof Listeners>(eventName: EventName) {
    if (!this.listeners[eventName]) {
      // create subject
      this.listeners[eventName] = new Subject();
      // post message to worker - start listening the event
      this.postMessage({
        type: WorkerMessageType.ListenSocketEvent,
        data: {
          socketName: this.socketName,
          socketEvent: eventName
        }
      });
    }
    return this.listeners[eventName];
  }

  emit<EventName extends keyof Emitters>(event: EventName, data: Emitters[EventName]) {
    const postMsgData: IWMSocketEmitData = {
      socketName: this.socketName,
      socketEvent: event,
      eventData: data,
    };

    this.postMessage({
      type: WorkerMessageType.SocketEmit,
      data: postMsgData,
    });
    return true;
  }

}
