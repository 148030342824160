import { Injectable } from '@angular/core';
import { BaseRestService } from './@base.rest.service';
import { environment } from '../../../environments/environment';
import { IChatFormRestEntity } from '../../entity/chat/chat-form.entity';
import { DeferredMessageEntity, IDeferredMessageEntity } from '../../entity/chat/deferred-message.entity';
import { map } from 'rxjs';
import {
  IAddContactHistoryItem,
  IAddContactHistoryResponse,
  IUpdateContactHistoryItem
} from './types/chat-rest/contact-history-item.interface';
import { ContactHistoryItemEntity } from '../../modules/crm/components/client/client-types/contact-history-item.entity';

const Endpoints = {
  viberInvitationLink: `${environment.viberClient.serverUrl}/links/generateLink`,
  chatForms: `${environment.rest.apiUrl}/chat/forms/list`,
  getDeferredMessages: `${environment.rest.apiUrl}/chat/chat/get-def-messages`,
  addContactHistory: `${environment.rest.apiUrl}/chat/history/add-history`,
  updHistoryComment: `${environment.rest.apiUrl}/chat/history/upd-history-comment`,
} as const;

@Injectable({
  providedIn: 'root'
})
export class ChatRestService extends BaseRestService {
  
  getViberInvitationLink(clientId: number, projectId: number) {
    return this.get<{ data: string }>(Endpoints.viberInvitationLink, { params: { clientId, projectId } });
  }
  
  getChatForms() {
    return this.get<IChatFormRestEntity[]>(Endpoints.chatForms);
  }
  
  getDefMessages(params: { siteId: number, userId: number }) {
    return this.get<IDeferredMessageEntity[]>(Endpoints.getDeferredMessages, { params })
        .pipe(map(res => (res || []).map(m => new DeferredMessageEntity(m))));
  }
  
  addContactHistory(item: IAddContactHistoryItem) {
    return this.post<IAddContactHistoryResponse, IAddContactHistoryItem>(Endpoints.addContactHistory, item);
  }
  
  updateContactHistoryComment(data: IUpdateContactHistoryItem) {
    return this.post<{ status: any }, IUpdateContactHistoryItem>(Endpoints.updHistoryComment, data);
  }
  
}
