import {
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {debounceTime, Subject, takeUntil} from 'rxjs';
import { fuseAnimations } from '@fuse/animations/public-api';
import {
    TContactSearchMode,
    TContactSearchModeInputType
} from '../../modules/crm/components/contacts/contacts-types/contacts-route-params.interface';
import {Router} from '@angular/router';

interface ISearchModeItem {
    title: string;
    mode: TContactSearchMode;
    type: TContactSearchModeInputType[];
}

const queryParams: ISearchModeItem[] = [
    {title: 'User Id', mode: 'user_id', type: ['string', 'number']},
    {title: 'User Phone', mode: 'phone', type: ['number', 'string']},
    {title: 'Social ID', mode: 'social_id', type: ['string', 'number']},
    {title: 'Email', mode: 'email', type: ['number', 'string', 'email']},
    {title: 'User Login', mode: 'login', type: ['string', 'number']},
];

@Component({
    selector     : 'search',
    templateUrl  : './search.component.html',
    styleUrls    : ['./search.components.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy
{
    @Input() appearance: 'basic' | 'clientSearch' = 'basic';
    @Input() debounce: number = 300;
    @Input() minLength: number = 2;
    
    private basicSearch = new Subject<string>();
    @Output() onBasicSearch = this.basicSearch.pipe(debounceTime(500));

    opened: boolean = false;
    searchControl: FormControl = new FormControl('');
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    queryForView: ISearchModeItem[] = queryParams;
    selectedModeIndex = 0;

    constructor(private router: Router) { }
    
    searchByModeIndex(index: number) {
        const searchString = this.searchControl.value;
        if (searchString) {
            const queryMode = this.queryForView[index];
            // TODO: implement googleAnalyticsService service
            // this.googleAnalyticsService.eventEmitter('Header-search', queryMode);
            this.router.navigate([`/crm/contacts/${queryMode.mode}/${searchString}`]);
            this.close();

            // select last searched mode
            if (this.selectedModeIndex !== index) {
                this.selectedModeIndex = index;
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Host binding for component classes
     */
    @HostBinding('class') get classList(): any {
        return {
            'search-appearance-bar'  : this.appearance === 'clientSearch',
            'search-appearance-basic': this.appearance === 'basic',
            'search-opened'          : this.opened,
            'skip-clicks-inside'     : this.appearance === 'clientSearch',
        };
    }

    /**
     * Setter for clientSearch search input
     *
     * @param value
     */
    @ViewChild('clientSearchInput')
    set clientSearchInput(value: ElementRef) {
        // If the value exists, it means that the search input
        // is now in the DOM and we can focus on the input..
        if ( value ) {
            // Give Angular time to complete the change detection cycle
            setTimeout(() => {

                // Focus to the input element
                value.nativeElement.focus();
            });
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnChanges(changes: SimpleChanges): void {
        // Appearance
        if ( 'appearance' in changes )
        {
            // To prevent any issues, close the
            // search after changing the appearance
            this.close();
        }
    }

    ngOnInit(): void {
        this.searchControl.valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value: string) => {
                if (this.appearance === 'clientSearch') {
                    if (value && value.indexOf('@') > -1) {
                        this.filterSearchMode('email');
                        return;
                    }
                    // restore if not full list
                    if (this.queryForView.length !== queryParams.length) {
                        this.queryForView = queryParams;
                    }
                }
                
                if (this.appearance === 'basic') {
                    this.basicSearch.next(value);
                }

            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
    
    private filterSearchMode(inputType: TContactSearchModeInputType) {
        this.queryForView = queryParams.filter(modeItem => modeItem.type.indexOf(inputType) > -1);
        if (this.selectedModeIndex > this.queryForView.length - 1) {
            this.selectedModeIndex = 0;
        }
    }

    onKeydown(event: KeyboardEvent): void {
        // Listen for escape to close the search
        // if the appearance is 'clientSearch'
        if ( this.appearance === 'clientSearch' ) {
            // Escape
            switch (event.code) {
                case 'Escape':
                    this.close();
                    break;
                case 'ArrowDown':
                case 'ArrowUp':
                    const direction = event.code === 'ArrowUp' ? -1 : 1;
                    const nextModeIndex = this.selectedModeIndex + direction;
                    if (nextModeIndex > -1 && nextModeIndex < this.queryForView.length) {
                        this.selectedModeIndex = nextModeIndex;
                    }
                    break;
                default:
            }
        }
    }

    /**
     * Open the search
     * Used in 'clientSearch'
     */
    open(): void {
        // Return if it's already opened
        if ( this.opened ) {
            return;
        }

        // Open the search
        this.opened = true;
    }

    /**
     * Close the search
     * * Used in 'clientSearch'
     */
    close(): void {
        // Return if it's already closed
        if ( !this.opened ) {
            return;
        }

        // Close the search
        this.opened = false;
    }
}
