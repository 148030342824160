import { Type } from "@angular/core";
import { ExtraOptions, PreloadAllModules, Route } from "@angular/router";
import AuthNavigation from './auth';
import { CrmModuleDefaultRoute } from './crm';

const RouterDefaultConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

const AppRouting: Route[] = [
    ...AuthNavigation,
    CrmModuleDefaultRoute,
    {
        path: '',
        pathMatch : 'full',
        redirectTo: 'auth'
    },
    {
        path: '**',
        redirectTo: 'auth'
    }
];

const DefaultRouting = (component: Type<any>): Route[] => {
    return [
        {
            path     : '',
            component
        }
    ]
};

export {
    AppRouting,
    DefaultRouting,
    RouterDefaultConfig
};
