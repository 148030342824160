import {TYhRoute} from '../../../navigation-interfaces/navigation.interfaces';
import {PermissionsGuard} from '../../../../core/guards/permissions.guard';

export const SettingsEventsNavigation: TYhRoute[] = [
    {
        path: 'events',
        canActivate: [PermissionsGuard],
        fuseNavConfig: {
            title: 'Events',
            type: 'collapsable',
        },
        permissionFree: true,
        children: [
            {
                path: 'lost-reasons',
                loadChildren: () => import('app/modules/crm/components/settings/events/lost-reasons/lost-reasons.module').then(m => m.LostReasonsModule),
                fuseNavConfig: {
                    title: 'Lost Reasons',
                    type: 'basic',
                },
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'settings.events.lost_reasons.view'
                }
            }
        ]
    }
];
