import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import OperatorEntity from '../entity/operator.entity';
import { TPermissionsCheck } from '../navigation/navigation-interfaces/navigation.interfaces';
import { debounceTime, map, Observable } from 'rxjs';

@Injectable()
export class PermissionsService {
    
    $permissionChanged: Observable<boolean>;
    private operator: OperatorEntity;
    
    constructor(private authService: AuthService) {
        this.authService.$operator.subscribe(op => this.operator = op);
        this.$permissionChanged = this.authService.$operator.pipe(map(() => true));
    }
    
    hasPermission(checkPerm: string): boolean {
        if (!this.operator) {
            return false;
        }
        return this.operator.hasPermission(checkPerm);
    }
    
    hasPermissions(permissions: string[], logic: TPermissionsCheck): boolean {
        if (!this.operator) {
            return false;
        }
        return this.operator.hasPermissions(permissions, logic);
    }
    
    hasPermissionAsync(checkPerm: string) {
        return this.authService.$operator.pipe(
            debounceTime(10),
            map(() => this.hasPermission(checkPerm))
        );
    }
    
}
