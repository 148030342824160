import { CrmModulePath } from '../../navigation/crm';
import { SecurityNavigationEnum } from '../../navigation/crm/security/security.navigation';
import { SettingsNewsFeedGroupNavigationEnum } from '../../navigation/crm/settings/news-feed-groups/settings-news-feed-groups.navigation';
import {
    SecurityEditGroupNavigationEnum
} from '../../navigation/crm/security/edit-group/security-edit-group.navigation';
import { AuthModulePath, AuthNavigationEnum } from '../../navigation/auth';
import {
    SettingsNewsFeedGroupEditNavigationEnum
} from '../../navigation/crm/settings/news-feed-groups/edit-group/news-feed-groups-edit.navigation';

export class RoutesHelper {
    static readonly Auth = `/${AuthModulePath}`;
    static readonly SignIn = `/${AuthModulePath}/${AuthNavigationEnum.signIn}`;
    static readonly SecurityGroups = `/${CrmModulePath}/${SecurityNavigationEnum.root}/${SecurityNavigationEnum.groups}`;
    static readonly SecurityGroupEdit = `/${CrmModulePath}/${SecurityNavigationEnum.root}/${SecurityNavigationEnum.groups}/${SecurityEditGroupNavigationEnum.edit}`;
    static readonly SettingsNewsFeedGroup = `/${CrmModulePath}/${SettingsNewsFeedGroupNavigationEnum.root}/${SettingsNewsFeedGroupNavigationEnum.groups}/${SettingsNewsFeedGroupNavigationEnum.groups}`
    static readonly SettingsNewsFeedGroupEdit = `/${CrmModulePath}/${SettingsNewsFeedGroupNavigationEnum.root}/${SettingsNewsFeedGroupNavigationEnum.groups}/${SettingsNewsFeedGroupEditNavigationEnum.edit}`
}
