import { Component } from '@angular/core';
import { YhToastrAbstractComponent } from '../yh-toastr-abstract.component';
import { TSimpleNotificationType } from './yh-toastr-simple.types';


// component data
type TToasterSimpleComponentData = {
    message: string;
    type?: TSimpleNotificationType;
};

@Component({
    selector: 'simple-notification-template',
    template: `
    <div class="mat-simple-snackbar flex flex-row justify-between items-center">
      <span>{{data?.message}}</span>
      <div class="actions">
        <button mat-icon-button (click)="closeNotification()" matTooltip="Close">
          <mat-icon class="text-inherit">close</mat-icon>
        </button>
        <button mat-icon-button (click)="skipAllNotifications()" matTooltip="Skip all">
          <mat-icon class="text-inherit">done_all</mat-icon>
        </button>
      </div>
    </div>
  `
})
export class YhToastrSimpleComponent extends YhToastrAbstractComponent<TToasterSimpleComponentData> { }
