export enum WorkerMessageType {
    Error = -1,
    Close,
    Message,
    Logout,
    CreateSocket,
    ListenSocketEvent,
    SocketEventOccurred,
    SocketEmit,
    SocketConnectedState,
}
