<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    *ngIf="navigationItems"
    [appearance]="navigationAppearance"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigationItems"
    resizeObserver
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center mt-2">
            <yhelper-logo [size]="48"></yhelper-logo>
            <div class="logo-text">
                <div class="logo-text__content flex flex-col pl-4 cursor-default">
                    <span class="font-medium text-xl">YHelper</span>
                    <span class="text-secondary text-xs">v: 3.0.20211213.1</span>
                </div>
            </div>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <div class="flex items-center pr-2 space-x-2">
            <!-- Navigation appearance toggle button -->
            <button
                class="hidden md:inline-flex"
                mat-icon-button
                (click)="toggleNavigationAppearance()">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </div>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <search [appearance]="'clientSearch'"></search>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <shortcuts></shortcuts>
            <notifications></notifications>
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

</div>
