import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthService} from "../../../../services/auth.service";
import OperatorEntity from "../../../../entity/operator.entity";
import {Subscription} from "rxjs";
import {ChatService} from "../../../../services/chat.service";

@Component({
    selector: 'profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent implements OnInit, OnDestroy {

    public operator: OperatorEntity;
    public readonly profileMenu = [
        {
            path: './general',
            name: 'General'
        },
        {
            path: './personal-settings',
            name: 'Personal settings'
        },
        {
            path: './private-phrases',
            name: 'Private phrases'
        }
    ];

    private subscription = new Subscription();
    constructor(
        private authService: AuthService,
        private chatService: ChatService,
    ) {
        this.operator = this.authService.operator;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnInit() {

    }

}
