import BaseEntity from "./@base.entity";

export default class OperatorProjectEntity extends BaseEntity {

    private readonly defaultProjectSettings = {
        maxChats: 0,
        photo: '',
        userName: ['', undefined]
    };
    maxChats: number;
    name: string;
    projectId;
    photo: string;
    operatorName: string;

    constructor(data: any = {}) {
        super(data);
        this.name = data.name;
        this.projectId = data.siteId;
        this.photo = data.photo;
        this.operatorName = data.userName;
        this.maxChats = isNaN(+data.maxChats) ? 0 : +data.maxChats;
        // TODO check login field
        // login: ""
    }

    get hasCustomValues() {
        return Object.keys(this.defaultProjectSettings).some((settingKey) => {
            if (Array.isArray(this.defaultProjectSettings[settingKey])) {
                return this.defaultProjectSettings[settingKey].indexOf(this[settingKey]) === -1;
            }
            return this[settingKey] !== this.defaultProjectSettings[settingKey];
        })
    }

}
