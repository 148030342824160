import BaseEntity from "./@base.entity";

export default class WhatsappPhonesEntity extends BaseEntity {

    id;
    phone;
    description: string;

    constructor(data: any = {}) {
        super(data);
        this.id = data.id;
        this.phone = data.phone;
        this.description = data.description;
        // this.storage = data.storage;
    }

}
