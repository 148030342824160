import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import {StorageService} from "../../../services/storage.service";
import {LocalStorageConfig} from "../../../core/config/localStorage.config";
import {YhNavigationService} from "../../../services/navigation.service";
import {IYhFuseNavigationItem, IYhNavigation} from '../../../navigation/navigation-interfaces/navigation.interfaces';

@Component({
    selector: 'dense-layout',
    templateUrl: './dense.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./dense.component.scss']
})
export class DenseLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigationItems: IYhFuseNavigationItem[];
    navigationAppearance: 'default' | 'dense' = 'default';
    private _unsubscribeAll: Subject<null> = new Subject<null>();

    constructor(private navigationService: YhNavigationService,
                private fuseMediaWatcherService: FuseMediaWatcherService,
                private storageService: StorageService) {
        const storedNavigationAppearance = this.storageService.get(LocalStorageConfig.keys.navigationAppearance, 'session');
        if (storedNavigationAppearance) {
            this.navigationAppearance = storedNavigationAppearance;
        }
    }

    ngOnInit(): void {
        // Subscribe to navigation data
        this.navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: IYhNavigation) => {
                this.navigationItems = navigation.default;
            });

        // Subscribe to media changes
        this.fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    toggleNavigationAppearance(): void {
        this.navigationAppearance = (this.navigationAppearance === 'default' ? 'dense' : 'default');
        this.storageService.set(LocalStorageConfig.keys.navigationAppearance, this.navigationAppearance, 'session');
    }
}
