import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { YhToastrAbstractComponent } from './templates/yh-toastr-abstract.component';
import { ComponentType } from '@angular/cdk/overlay';
import {
    SimpleNotificationPanelClassMap,
} from './templates/simple/yh-toastr-simple.types';
import {
    YhToastrTypeToComponentMap,
    TYhToastrTypeToTemplate,
    YhToastrTypeEnum
} from './types/toastr-notification-templates.map';
import { YhMatSnackBarDefaultOptions } from './types/mat-snack-bar-default.options';

// type of 'data' property of the template component
type TTemplateData<T extends YhToastrTypeEnum> = TYhToastrTypeToTemplate[T]['data'];
// config without 'data'
type TYhMatSnackBarConfig = Omit<MatSnackBarConfig, 'data'>;
// config including 'data' as required
type TYhMatSnackBarConfigFull<T extends YhToastrTypeEnum> = { data: TTemplateData<T> } & Omit<MatSnackBarConfig, 'data'>;


type TToasterNotificationQueueItem<T extends YhToastrTypeEnum> = {
    component: TYhToastrTypeToTemplate[T];
    config: TYhMatSnackBarConfigFull<T>;
}

interface IShowErrorMessage {
    // review usage of error prop
    message?: string;
    duration?: number;
    error?: IShowErrorMessage;
}

const DefaultDuration = YhMatSnackBarDefaultOptions.duration || 2000;

@Injectable({
    providedIn: 'root'
})
export class YhToastrService {
    
    private queue: TToasterNotificationQueueItem<any>[] = [];
    
    private currentNotification: MatSnackBarRef<YhToastrAbstractComponent>;
    
    constructor(private matSnackBar: MatSnackBar,
                private ngZone: NgZone,
    ) { }

    showTemplatedMessage<T extends YhToastrTypeEnum>(tplType: T, data: TTemplateData<T>, config?: TYhMatSnackBarConfig) {
        this.addNotificationToQueue(tplType, { ...config || {}, data });
        this.showNextFromQueue();
    }
    
    showMany(messagesArray: string[]) {
        const isArray = Array.isArray(messagesArray);
        const messagesToShow = (isArray ? messagesArray : []).slice();
        return {
            error: (config?: TYhMatSnackBarConfig) => {
                if (!isArray) {
                    // in case of showing errors and no errors array was provided - show default Error message
                    messagesToShow.push('Error');
                }
                messagesToShow.forEach(msg => this.show(msg).error(config));
            },
            success: (config?: TYhMatSnackBarConfig) => messagesToShow.forEach(msg => this.show(msg).success(config)),
        }
    }

    show(notificationText: string) {
        const message = String(notificationText);
        return {
            error: (config?: TYhMatSnackBarConfig) => this.showNotification({ message, type: 'error' }, config),
            success: (config?: TYhMatSnackBarConfig) => this.showNotification({ message, type: 'success' }, config)
        }
    }
    
    showErrorMessage(data: IShowErrorMessage) {
        const { duration, message, error } = data || {};
        if (error) {
            // review this
            setTimeout(() => {
                this.showErrorMessage(error);
            }, duration || 1500);
        }
        this.show(message).error({ duration });
    }

    private showNotification(data: TTemplateData<YhToastrTypeEnum.Simple>, config?: TYhMatSnackBarConfig) {
        const useConfig = config || {};
        useConfig.duration = useConfig.duration || DefaultDuration;
        this.addNotificationToQueue(YhToastrTypeEnum.Simple, {
            panelClass: SimpleNotificationPanelClassMap[data.type],
            ...(useConfig || {}),
            data,
        });
        this.showNextFromQueue();
    }

    private skipAll() {
        this.queue = []; // clear queue
        if (this.currentNotification) {
            // close current notification
            this.currentNotification.dismiss();
            this.currentNotification = null;
        }
    }
    
    private addNotificationToQueue<T extends YhToastrTypeEnum>(tplType: T, config: TYhMatSnackBarConfigFull<T>) {
        const component = YhToastrTypeToComponentMap[tplType]();
        this.queue.push({
            component,
            config,
        });
    }
    
    private showNextFromQueue() {
        if (!this.currentNotification) {
            const nextMessage = this.queue.shift();
            if (nextMessage) {
                const { config, component } = nextMessage;
                this.ngZone.run(() => {
                    // open notification
                    this.currentNotification = this.openFromComponent(component, config);
                    // set common methods
                    const { instance } = this.currentNotification;
                    instance.skipAllNotifications = this.skipAll.bind(this);
                    instance.closeNotification = this.currentNotification.dismiss.bind(this.currentNotification);
                    // subscribe to afterDismissed()
                    this.currentNotification.afterDismissed().subscribe(() => {
                        this.currentNotification = null;
                        this.showNextFromQueue();
                    });
                });
            }
        }
    }
    
    private openFromComponent<T extends YhToastrAbstractComponent>(component: ComponentType<T>, config: MatSnackBarConfig<T['data']>) {
        return this.matSnackBar.openFromComponent(component, config);
    }
}
