import { Injectable } from "@angular/core";
import { BaseRestService } from "./@base.rest.service";
import { map, Observable } from "rxjs";
import LocaleEntity from "../../entity/locale.entity";
import GroupEntity from "../../entity/group.entity";
import EmailsEntity from "../../entity/emails.entity";
import TelegramPhonesEntity from "../../entity/telegramPhones.entity";
import WhatsappPhonesEntity from "../../entity/whatsappPhones.entity";
import { TMappingDictionary } from '../../core/interfaces/common/mapping-dictionary.type';
import {
    ISecurityGroupRest,
    SecurityGroupRestEntity
} from './types/security-rest/security-group.interface';
import { ISecurityGroupDetailed } from './types/security-rest/security-group-detailed.interface';
import { ISecurityPermission } from './types/security-rest/security-permission.interface';
import { TSecurityEditGroupBody } from './types/security-rest/security-edit-group-body.interface';

export type ChangePassword = { password: string };

type UsersInfoEntriesEntityMappingType = {
    local: LocaleEntity,
    group: GroupEntity,
    emails: EmailsEntity,
    telegramPhones: TelegramPhonesEntity,
    whatsappPhones: WhatsappPhonesEntity
};

type TUsersInfoEntriesEntityMapDictionary = TMappingDictionary<UsersInfoEntriesEntityMappingType>;

const UsersInfoEntriesEntityMapping: TUsersInfoEntriesEntityMapDictionary = {
    local: LocaleEntity,
    group: GroupEntity,
    emails: EmailsEntity,
    telegramPhones: TelegramPhonesEntity,
    whatsappPhones: WhatsappPhonesEntity
};

type AllowedUserInfoEntries = keyof UsersInfoEntriesEntityMappingType;

@Injectable({
    providedIn: 'root'
})
export class SecurityRestService extends BaseRestService {
    
    readonly endpoints = {
        operator: '/security/users/me',
        changePassword: '/security/users/change-password',
        usersInfo: '/security/users/info',
        
        getGroups: `/security/groups/view/all`,
        getGroupDetailed: `/security/groups/view`,
        getGroupPermissions: `/security/groups/information?type[0]=permissions-list`,
        editGroup: `/security/groups/edit`,
        // deleteGroup: `/security/groups/delete/`,
        // createGroup: `/security/groups/create`,
    };
    
    getMe(): Observable<any> {
        return this.get(this.endpoints.operator);
    }
    
    changePassword(data: ChangePassword): Observable<{passwordChanged: boolean}> {
        return this.post(this.endpoints.changePassword, data);
    }
    
    getUsersInfo<T extends AllowedUserInfoEntries>(types: Array<T>) {
        
        type TResponse = { [K in T]: any[] };
        type TBody = { types: Array<AllowedUserInfoEntries> };
        type TResult = { [K in T]: UsersInfoEntriesEntityMappingType[K][] };
        
        return this.post<TResponse, TBody>(this.endpoints.usersInfo, { types }).pipe(
            map(response => {
                const result: Partial<TResult> = {};
                
                types.forEach((key: keyof UsersInfoEntriesEntityMappingType) => {
                    const Entity = UsersInfoEntriesEntityMapping[key];
                    const arr = response[key] || [];
                    result[key] = arr.map(item => new Entity(item));
                });
                
                return result as TResult;
            })
        );
    }
    
    getGroups() {
        return this.get<ISecurityGroupRest[]>(this.endpoints.getGroups)
            .pipe(map(res => (res || []).map(item => new SecurityGroupRestEntity(item))));
    }
    
    getGroupDetailed(groupId: number) {
        return this.get<ISecurityGroupDetailed>(`${this.endpoints.getGroupDetailed}/${groupId}`);
    }
    
    getGroupPermissions() {
        return this.get<{ 'permissions-list': ISecurityPermission[] }>(this.endpoints.getGroupPermissions);
    }
    
    editGroup(groupId: number, data: TSecurityEditGroupBody) {
        return this.post< { model: Partial<ISecurityGroupDetailed> }, TSecurityEditGroupBody>(`${this.endpoints.editGroup}/${groupId}`, data);
    }
    
}
