import { MatDateFormats } from '@angular/material/core';

export const YhMatDateFormats: MatDateFormats = {
    parse: {
        dateInput: ['DD.MM.YYYY', 'L'],
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    }
}
